import {
  Controller
} from "stimulus";

export default class extends Controller {

  static targets = ["trigger", "inner"]

  toggle() {
    this.innerTarget.classList.toggle('hidden');
    var text = this.triggerTarget.innerText;
    if (text == "Details") {
      this.triggerTarget.innerText = "Hide";
    } else {
      this.triggerTarget.innerText = "Details";
    };
  }
}